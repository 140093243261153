import { AuthService } from './../service/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardGuard implements CanActivate {
  constructor(private auth: AuthService, private navCtrl: NavController) {
    if (!this.auth.isLoggedIn()) {
      this.navCtrl.navigateRoot(['login']);
    }
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (next.data.page === 'register' || next.data?.page === 'login') {
      return !this.auth.isLoggedIn();
    } else {
      return this.auth.isLoggedIn();
    }
  }
}
