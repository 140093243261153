import { AuthGuardGuard } from './common/guard/auth-guard.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AccessGuardGuard } from './common/guard/access-guard.guard';

const routes: Routes = [
  {
    path: 'stocks',
    canActivate:[AccessGuardGuard],
    loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'expense',
    canActivate:[AccessGuardGuard],
    loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'customers',
    canActivate:[AccessGuardGuard],
    loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'sales',
    canActivate:[AccessGuardGuard],
    loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'permission',
    loadChildren: () => import('./permission/permission.module').then((m) => m.PermissionModule),
    data:{page:'permission'}
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then((m) => m.RegisterPageModule),
    canActivate: [AuthGuardGuard],
    data: { page: 'register' },
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginPageModule),
    // canActivate: [AuthGuardGuard],
    data: { page: 'login' },
  },
  {
    path: 'users',
    loadChildren: () => import('./user/user.module').then( m => m.UserPageModule),
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'stores',
    loadChildren: () => import('./stores/stores.module').then((m) => m.StoresModule),
  },
  {
    path: '',
    canActivate:[AccessGuardGuard],
    loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
