import { SharedModule } from './common/shared/shared.module';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { StoreSelectComponent } from './common/component/store-select/store-select.component';
import { ApiService } from './common/service/api.service';
import { AuthService } from './common/service/auth.service';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        SharedModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        AuthService,
        ApiService,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        // {
        //   provide: APP_INITIALIZER,
        //   useFactory:(auth: AuthService)=>{return auth.setModules()},
        //   deps:[ApiService, AuthService]
        // }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
