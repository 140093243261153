import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  constructor(public toastController: ToastController) {}

  async info(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position:"top"
    });
    toast.present();
  }

  async error(msg: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      position:"top",
      color: 'warning',
    });
    toast.present();
  }
}
