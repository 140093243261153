import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateConvertPipe } from './../date-convert.pipe';
import { PricePipe } from './../price.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoutComponent } from '../logout/logout.component';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { SaleListComponent } from 'src/app/sales/sale-list/sale-list.component';
import { SaleViewComponent } from 'src/app/sales/sale-view/sale-view.component';
import { StoreSelectComponent } from '../component/store-select/store-select.component';
import { PrintComponent } from '../component/print/print.component';
import { SaleHeaderProductComponent } from 'src/app/sales/sale-list/sale-header-product/sale-header-product.component';



@NgModule({
  declarations: [PricePipe, LogoutComponent, DateConvertPipe, SaleViewComponent, SaleListComponent, StoreSelectComponent, PrintComponent, SaleHeaderProductComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
  ],
  exports: [PricePipe, LogoutComponent, DateConvertPipe, SaleViewComponent, SaleListComponent, StoreSelectComponent, PrintComponent, SaleHeaderProductComponent]
})
export class SharedModule { }
