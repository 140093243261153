import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from '../service/api.service';
import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AccessGuardGuard implements CanActivate {

  constructor(private auth: AuthService, private apiService: ApiService, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return new Promise((resolve, reject) => {
      if (AuthService.moduleAccess.length < 1) {
        return this.apiService.get(environment.userGetModules + this.auth.getToken()).then(res => {
          this.auth.setModules(res.result);
          if (this.takeAction(state.url)) {
            this.apiService.get(environment.userGetStores).then(res=>{
             this.auth.setUserStores(res.result);
             resolve(true);
            });
           
          } else {
            const res2 = this.getRouteName(AuthService.moduleAccess);
            resolve(false);
            this.router.navigate([res2]);

          }
        })

      } else {
        if (this.takeAction(state.url)) {
          resolve(true);
        } else {
          const res2 = this.getRouteName(AuthService.moduleAccess);
          resolve(false);
          this.router.navigate([res2]);
        }
      }

    })
  }

  checkIfRouteActive(modules: any[], currentRoute: string) {
    const routeParam = currentRoute.split("/");
    return modules.find(x => x.type === routeParam[routeParam.length - 1])?.view > 0;
  }

  getRouteName(modules: any[]) {
    const m = modules.find(x => x.view == 1);
    if(AuthService.moduleAccess.length==0)
    return "login";
    return m ? 'tabs/' + m.type : "permission";
  }

  takeAction(url) {
    const active = this.checkIfRouteActive(AuthService.moduleAccess, url);
    if (active)
      return true;
    else {
      return false

    }
  }


}
